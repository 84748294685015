import React from 'react';

export const SignaturePreview = ({ template, data }) => {

    let logo = ''
    let siteUrl = ''
    let siteDomain = ''
    switch(data.country){
        case 'ke':
            logo = "http://cdn.snapplify.com/sig/KenyanLogo.png";
            siteDomain = "snapplify.co.ke";
            siteUrl = "https://snapplify.co.ke";
            break;
        default:
            logo = "http://cdn.snapplify.com/sig/Logo.png";
            siteDomain = "snapplify.com";
            siteUrl = "https://snapplify.com";
    }

    const handleCopyToClipboard = () => {
        const signature = generateSignature();
        const tempElement = document.createElement('textarea');
        tempElement.value = signature;
        document.body.appendChild(tempElement);
        tempElement.select();
        document.execCommand('copy');
        document.body.removeChild(tempElement);
        alert('Signature copied to clipboard!');
    };

    const generateSignature = () => {
        if (template === 'Education') {
            return generateEducationSignature()
        } else {
            return generateTeamSignature()
        }
    }

    const generateEducationSignature = () => `
<html>
    <body>
    <table width="504" height="203" cellpading="0" border="0" cellspacing="0" style="font-family: Helvetica;">
      <tr style="background-color: #FFF;">
        <td style="padding-left:16px;padding-top:0px;" width="236">
          <table style="height: 55px;">
            <tr>
              <td style="height: 20px;width: 200px;color: #4A4A4A;font-family: Helvetica; font-size: 20px;font-weight: bold;line-height: 20px;">${data.name}</td>
            </tr>
            <tr>
              <td style="width: 101px;color: #7A7A7A;font-family: Helvetica;  font-size: 14px;line-height: 15px;vertical-align: top;">${data.position}</td>
            </tr>
          </table>
          <table>
            <tr>
              <td valign="middle" height="12" width="16"><img src="http://cdn.snapplify.com/sig/Mail_icon.png" alt="Snapplify email icon" width="12" height="auto" /></td>
              <td>
                <a href="mailto:${data.email}" style="height: 80px;width: 123px;color: #4A4A4A;font-family: Helvetica;font-size: 11px;line-height: 15px;text-decoration:none;outline:none;border:none;">${data.email}</a>
              </td>
            </tr>
            
            ${data.cellnumber ? (`
              <tr>
                <td valign="middle" height="12"><img src="http://cdn.snapplify.com/sig/Cell_icon.png" alt="Snapplify cell icon" width="8" height="13" /></td>
                <td>
                  <a href="tel:${data.cellnumber}" style="height: 80px;width: 123px;color: #4A4A4A;font-family: Helvetica;font-size: 11px;line-height: 15px;text-decoration:none;outline:none;border:none;">${data.cellnumber}</a>
                </td>
              </tr>
            `) : ''}
            
            <tr>
              <td valign="middle" height="12"><img src="http://cdn.snapplify.com/sig/Tel_icon.png" alt="Snapplify telephone icon" width="12" height="12" /></td>
              <td>
                <a href="tel:${data.officenumber}" style="height: 80px;width: 123px;color: #4A4A4A;font-family: Helvetica;font-size: 11px;line-height: 15px;text-decoration:none;outline:none;border:none;">${data.officenumber}</a>
              </td>
            </tr>
          </table>
        </td>
        <td valign="top" style="padding-top:12px;text-align:center">
        <a href="https://www.snapplify.com/" target="blank" style="text-decoration:none;outline:none;border:none;">
          <img src="http://cdn.snapplify.com/sig/Logo_edu.png" alt="Snapplify Logo" width="206" height="51" style="border:0px; outline:none;" />
        </a>
        </td>
      </tr>
      <tr>
         <td height="66" colspan="2" style="height: 66px;width: 504px;background-color: #F4F4F4;margin-top:10px;">
          <table width="">
            <tr>
              <td colspan="3" height="36" style="height: 13px;width: 504px;color: #4A4A4A;font-family: Helvetica; font-size: 11px;font-weight: 300;line-height: 13px; text-align: center;">
                SUBSCRIBE TO OUR <a href="http://eepurl.com/bPlM09" style="text-decoration:none;outline:none;border:none;color: #4A4A4A;font-weight:bold;" target="blank">NEWSLETTER</a>&nbsp;&nbsp;|&nbsp;&nbsp;FIND OUT MORE AT <a href="https://engage.snapplify.com/" style="text-decoration:none;outline:none;border:none;color: #4A4A4A;font-weight:bold;" target="blank">ENGAGE.SNAPPLIFY.COM</a>
              </td>
            </tr>
            <tr width="308">
              <td width="" style="text-align: center;padding-top: 3px;" height="30">
                <a href="https://www.facebook.com/snapplify/?fref=ts" target="blank" style="text-decoration:none;outline:none;border:none;"><img src="http://cdn.snapplify.com/sig/Facebook.png" alt="Snapplify Facebook" width="80" height="20" style="border:0px; outline:none;" /></a>&nbsp;
                <a href="https://twitter.com/snapplify/" target="blank" style="text-decoration:none;outline:none;border:none;"><img src="http://cdn.snapplify.com/sig/Twitter.png" alt="Snapplify Twttier" width="83" height="21"  style="border:0px; outline:none;" /></a>&nbsp;
                <a href="https://www.linkedin.com/company/snapplify" target="blank" style="text-decoration:none;outline:none;border:none;"><img src="http://cdn.snapplify.com/sig/Linkedin.png" alt="Snapplify Linkedin" width="124" height="20"style="border:0px; outline:none;"  /></a>&nbsp;
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </body>
</html>
  `;

    const generateTeamSignature = () => `
<html>
    <body>
    <table width="504" height="203" cellpading="0" border="0" cellspacing="0" style="font-family: Helvetica;">
      <tr style="background-color: #FFF;">
        <td style="padding-left:16px;padding-top:0px;" width="236">
          <table style="height: 55px;">
            <tr>
              <td style="height: 20px;width: 200px;color: #4A4A4A;font-family: Helvetica; font-size: 20px;font-weight: bold;line-height: 20px;">${data.name}</td>
            </tr>
            <tr>
              <td style="width: 101px;color: #7A7A7A;font-family: Helvetica;  font-size: 14px;line-height: 15px;vertical-align: top;">${data.position}</td>
            </tr>
          </table>
          <table>
            <tr>
              <td valign="middle" height="12" width="16"><img src="http://cdn.snapplify.com/sig/Mail_icon.png" alt="Snapplify email icon" width="12" height="auto" /></td>
              <td>
                <a href="mailto:${data.email}" style="height: 80px;width: 123px;color: #4A4A4A;font-family: Helvetica;font-size: 11px;line-height: 15px;text-decoration:none;outline:none;border:none;">${data.email}</a>
              </td>
            </tr>
            
            ${data.cellnumber ? (`
              <tr>
                <td valign="middle" height="12"><img src="http://cdn.snapplify.com/sig/Cell_icon.png" alt="Snapplify cell icon" width="8" height="13" /></td>
                <td>
                  <a href="tel:${data.cellnumber}" style="height: 80px;width: 123px;color: #4A4A4A;font-family: Helvetica;font-size: 11px;line-height: 15px;text-decoration:none;outline:none;border:none;">${data.cellnumber}</a>
                </td>
              </tr>
            `) : ''}
            
            <tr>
              <td valign="middle" height="12"><img src="http://cdn.snapplify.com/sig/Tel_icon.png" alt="Snapplify telephone icon" width="12" height="12" /></td>
              <td>
                <a href="tel:${data.officenumber}" style="height: 80px;width: 123px;color: #4A4A4A;font-family: Helvetica;font-size: 11px;line-height: 15px;text-decoration:none;outline:none;border:none;">${data.officenumber}</a>
              </td>
            </tr>
          </table>
        </td>
        <td valign="top" style="padding-top:12px;text-align:center">
        <a href="https://www.snapplify.com/" target="blank" style="text-decoration:none;outline:none;border:none;">
          <img src="${logo}" alt="Snapplify Logo" width="206" height="51" style="border:0px; outline:none;" />
        </a>
        </td>
      </tr>
      <tr>
         <td height="66" colspan="2" style="height: 66px;width: 504px;background-color: #F4F4F4;margin-top:10px;">
          <table width="">
            <tr>
                <td colspan="3" height="36" style="height: 13px;width: 504px;color: #4A4A4A;font-family: Helvetica; font-size: 11px;font-weight: 300;line-height: 13px; text-align: center;">
                  SUBSCRIBE TO OUR <a href="http://eepurl.com/bPlM09" style="text-decoration:none;outline:none;border:none;color: #4A4A4A;font-weight:bold;" target="blank">NEWSLETTER</a>&nbsp;&nbsp;|&nbsp;&nbsp;SHOP AT <a href="${siteUrl}" style="text-transform:uppercase;text-decoration:none;outline:none;border:none;color: #4A4A4A;font-weight:bold;" target="blank">${siteDomain}</a>
                </td>
            </tr>
            <tr width="308">
              <td width="" style="text-align: center;padding-top: 3px;" height="30">
                <a href="https://www.facebook.com/snapplify/?fref=ts" target="blank" style="text-decoration:none;outline:none;border:none;"><img src="http://cdn.snapplify.com/sig/Facebook.png" alt="Snapplify Facebook" width="80" height="20" style="border:0px; outline:none;" /></a>&nbsp;
                <a href="https://twitter.com/snapplify/" target="blank" style="text-decoration:none;outline:none;border:none;"><img src="http://cdn.snapplify.com/sig/Twitter.png" alt="Snapplify Twttier" width="83" height="21"  style="border:0px; outline:none;" /></a>&nbsp;
                <a href="https://www.linkedin.com/company/snapplify" target="blank" style="text-decoration:none;outline:none;border:none;"><img src="http://cdn.snapplify.com/sig/Linkedin.png" alt="Snapplify Linkedin" width="124" height="20"style="border:0px; outline:none;"  /></a>&nbsp;
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </body>
</html>
    `

    return (
        <div>
            <iframe title="Signature Preview" srcDoc={generateSignature()} style={{width: '100%', height: '225px', border: '1px solid #ccc'}}/>
            <div className="mt-2">
                <button onClick={handleCopyToClipboard} className="btn btn-lg btn-primary"><i className="fa-solid fa-copy"></i> Copy Signature to Clipboard</button>
            </div>
        </div>
    );
};
