import React, { useState } from 'react';
import {TemplateSelector} from './TemplateSelector'
import {SignaturePreview} from './SignaturePreview'
import {SignatureForm} from './SignatureForm'
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import {jwtDecode} from 'jwt-decode';

export const App = () => {

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [template, setTemplate] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        position: '',
        email: '',
        officenumber: '',
        cellnumber: '',
        country: '',
    });

    const handleSuccess = (credentialResponse) => {
        try {
            const decoded = jwtDecode(credentialResponse.credential);
            const {name, email} = decoded;
            if (!email.endsWith('@snapplify.com')) {
                setErrorMessage('You must use a Snapplify email to login.');
                return;
            }
            setIsLoggedIn(true);
            setErrorMessage('');
            setFormData((prev) => ({
                ...prev,
                name,
                email,
            }));
        } catch (error) {
            console.error('Error decoding JWT:', error);
            setErrorMessage('Login failed. Please try again.');
        }
    };

    const handleError = () => {};

    return (
        <div>
            {!isLoggedIn ? (
                <GoogleOAuthProvider clientId="1025923892535-e3vma3dnhef0m14age4ops5s1gh0bqqn.apps.googleusercontent.com">
                    <div>
                        <h2>Hi, Please sign in with your <strong>snapplify.com</strong> email address.</h2>
                        <GoogleLogin onSuccess={handleSuccess} onError={handleError} />
                        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                    </div>
                </GoogleOAuthProvider>
            ) : ''}
            {isLoggedIn && !template && (
                <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{textAlign: "center", marginBottom: "1em"}}>
                        <h2>Please select <br/>which signature you want to use?</h2>
                    </div>
                    <TemplateSelector onSelect={setTemplate}/>
                </div>
            )}
            {isLoggedIn && template && (
                <div>
                    <div>
                        <h1>Your Details</h1>
                        <div className="copy mb-2">
                            Fill in your details to generate your email signature below.
                        </div>
                        <SignatureForm template={template} data={formData} onChange={setFormData}/>
                    </div>
                    <div>
                        <h1>Signature</h1>
                        <div className="copy mb-2">
                            Please be sure everything is correct. Refer to <a href="https://snapplify.slab.com/posts/team-email-signatures-h38p1dgl">the signature slab</a>.
                        </div>
                        <SignaturePreview template={template} data={formData}/>
                    </div>
                </div>
            )}
        </div>
    );

}


