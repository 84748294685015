import React from 'react';
import './App.css';
import {Header} from './components/Header'
import {Footer} from './components/Footer'
import {App} from "./components/App";
import Robot from './img/snappbot.png'

export const Layout = () => {

    return (
        <div className="App">
            <Header/>
            <section className="main-container" style={{flexGrow: 1}}>
                <div className="container">
                   <div className="main-content-box">
                       <div className="row">
                           <div className="col" style={{flex: 0}}>
                               <img className="robot" src={Robot} alt=""/>
                           </div>
                           <div className="col" style={{alignContent: "center", margin: "0 2.5em", flexGrow: 1}}>
                               <App/>
                           </div>
                       </div>
                   </div>
                </div>
            </section>
            <Footer/>
        </div>
    );
}

