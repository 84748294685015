import React, {useEffect, useRef} from 'react';

export const SignatureForm = ({ data, template, onChange }) => {

    const nameInputRef = useRef(null);
    const emailInputRef = useRef(null);
    useEffect(() => {
        if (template === 'Team') {
            if (nameInputRef.current)
                nameInputRef.current.focus();
        } else {
            if (emailInputRef.current)
                emailInputRef.current.focus();
        }

    }, [template]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        onChange({ ...data, [name]: value });
    };

    return (
        <form className="mt-4 mb-4">
            {template === 'Team' ? <input className="form-control" name="name" placeholder="Full Name" value={data.name} onChange={handleInputChange} ref={nameInputRef} /> : ''}
            <input className="form-control" name="email" placeholder="Email" value={data.email} onChange={handleInputChange} ref={emailInputRef} />
            <input className="form-control" name="position" placeholder="Title / Position" value={data.position} onChange={handleInputChange}/>
            <input className="form-control" name="officenumber" placeholder="Office Number" value={data.officenumber} onChange={handleInputChange}/>
            <input className="form-control" name="cellnumber" placeholder="Cell Number" value={data.cellnumber} onChange={handleInputChange}/>
            {template === 'Team' ?
            <select className="form-control" name="country" value={data.country} onChange={handleInputChange}>
                <option value="" disabled>Country</option>
                <option value="za">South Africa</option>
                <option value="uk">United Kingdom</option>
                <option value="ke">Kenya</option>
            </select> : ''}
        </form>
    );
};
